<!-- 客户话术弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'客户话术':'客户话术'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">
      <el-row>
        <el-row>
          <div class="sijiradio">
            <div class="diyixiang" style="margin: 20px;cursor: pointer;" v-for="(item,index) in List" :key="index" >
              <!--<div style="margin-right: 20px;display: flex;align-items: center;">-->
              <!--  <img v-if="index==isShow" src="@/assets/images/home/xuanzhonganniu.png" alt="">-->
              <!--  <img v-else src="@/assets/images/home/weixuanzhonganniu.png" alt="">-->
              <!--  <span style="font-size: 16px;font-weight: 500;color: #2d2f47;margin-left: 10px;">是否说出</span>-->
              <!--</div>-->
              <div class="listactive" :class="{listactive1:index==isShow}" @click="activeItem(item,index)">
                <div style="margin-bottom: 10px;">
                  <span>{{item.title}}</span>
                </div>
                <div>
                  {{item.content}}
                </div>
              </div>
            </div>
            <el-empty v-if="List.length == 0" description="暂无数据"></el-empty>
          </div>
        </el-row>
      </el-row>


    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <!--<el-button style="background: #53D1A1 !important; border-color: #53D1A1 !important; color: #FFFFFF !important;">-->
      <!--  拨打下游服务商电话-->
      <!--</el-button>-->
      <!--<el-button style="background: #5976E1 !important; border-color: #5976E1 !important; color: #FFFFFF !important;" @click="tel(data.owners_phone,'客户')">-->
      <!--  车主电话-->
      <!--</el-button>-->
      <!--<el-button-->
      <!--    style="margin-left: 20px;"-->
      <!--    type="primary"-->
      <!--    :loading="loading"-->
      <!--    @click="tel(data.loss_assessor_phone,'司机')">拨打司机电话-->
      <!--</el-button>-->
    </div>

  </el-dialog>
</template>

<script>

// 引入的接口
import {get_tel_no,} from '@/api/phone'
import {order_verbal} from "@/api/orderModule";

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      isShow:100,
      List:[
        // {
        //   content:'1. 这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术'
        // },
        // {
        //   content:'2. 这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术'
        // },
        // {
        //   content:'2. 这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术这是一段客户困境救援话术'
        // }
      ],

    };
  },
  watch: {
    data() {

    }
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getList();
  },
  methods: {
    //获取数据
    getList(){
      let params = {
        odd: this.data.order_id,
      }
      order_verbal(this.data.id,params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.List = res.data;
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
    },

    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },


  }
}
</script>

<style scoped lang="scss">
.sijilist{
  .sijilistactive{
    cursor: pointer;
    display: inline-block;
    width: 64px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    color: #868792;
    margin: 10px;
  }
  .sijilistactive1{
    color: #FF9B05;
    border-bottom: 4px solid #FF9B05;
  }
}

.sijiradio{

}
.listactive{
  width: 100%;
  border: 1px solid #ebecf2;
  border-radius: 6px;
  padding: 20px;
  margin-top: 15px;
}
.listactive1{
  border: 1px solid #fea837;
}
.diyixiang:first-child{
  margin-top: 0 !important;
}

</style>
